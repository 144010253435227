import moment from "moment"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { H4Bold, PBold } from "../brand/typography"
import { Colors } from "../brand/themes"

const Banner = ({
    endDate,
    hide,
    mobile,
    texts
}: { endDate: moment.Moment, hide: boolean, mobile: boolean, texts: string | ReactNode }) => {
    const [open, setOpen] = useState(true)

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    if (!open || hide)
        return null

    if (moment().isSameOrAfter(endDate, 'day')) {
        return null;
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "auto 52px",
            marginTop: "4px",
            width: "100%",
            backgroundColor: Colors.red()
        }}>
            <PBold secondary style={{ padding: "16px" }}>
                {texts}
            </PBold>
            <H4Bold secondary onClick={onClose} style={{ paddingTop: "8px", cursor: "pointer" }}>x</H4Bold>
        </div>
    )
}

export default Banner
